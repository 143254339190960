export const partners = [
  {
    title: 'PR Klub',
    logo: '/partners/pr-klub.png',
  },
  {
    title: 'British Chambers of Commerce',
    logo: '/partners/bcc.png',
  },
  {
    title: 'APRA',
    logo: '/partners/apra.png',
  },
  {
    title: 'Nordic Chamber of Commerce',
    logo: '/partners/nordic-chamber.png',
  },
  {
    title: 'CAMIC',
    logo: '/partners/camic.png',
  },
];
