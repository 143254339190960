import React from 'react';

export const Feature = ({ title, symbol, number, size, className }) => {
  return (
    <div className={`feature ${size} ${className || ''} grow`}>
      <div className="symbol">{symbol}</div>
      <div className="title">{title}</div>
      <div className="number">{number}</div>
    </div>
  );
};

export const features = [
  {
    title: 'Public relations',
    symbol: 'Pr',
    number: '59',
    size: 'lg',
  },
  {
    title: 'Sociální média',
    symbol: 'Sm',
    number: '62',
    size: 'sm',
  },
  {
    title: 'Mediální trénink',
    symbol: 'Mt',
    number: '109',
    size: 'sm',
  },
  {
    title: 'Krizová komunikace',
    symbol: 'K',
    number: '19',
    size: 'mid',
  },
  {
    title: 'Eventy',
    symbol: 'Es',
    number: '99',
    size: 'sm',
  },
  {
    title: 'Interní komunikace',
    symbol: 'I',
    number: '53',
    size: 'mid',
  },
  {
    title: 'Policy PR',
    symbol: 'Pi',
    number: '84',
    size: 'sm',
  },
  {
    title: 'Strategická komunikace',
    symbol: 'Sk',
    number: '64',
    size: 'sm',
  },
  {
    title: 'Public affairs',
    symbol: 'Pa',
    number: '91',
    size: 'sm',
  },
  {
    title: 'Grafický design',
    symbol: 'Gd',
    number: '65',
    size: 'sm',
  },
  {
    title: 'Foto/video',
    symbol: 'F',
    number: '9',
    size: 'sm',
  },
  {
    title: 'Weby',
    symbol: 'W',
    number: '74',
    size: 'sm',
  },
  {
    title: 'Podcasty',
    symbol: 'P',
    number: '15',
    size: 'sm',
  },
  {
    title: 'Influenceři',
    symbol: 'In',
    number: '49',
    size: 'sm',
  },
];
