import { Link } from 'gatsby';
import React from 'react';

export const MenuCloseIcon = ({ onClick }) => {
  return (
    <svg
      onClick={() => onClick()}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="currentColor"
      className="bi menu-controll menu-controll-open bi-x-lg grow2"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
      />
      <path
        fillRule="evenodd"
        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
      />
    </svg>
  );
};

export const MenuIcon = ({ onClick }) => {
  return (
    <svg
      onClick={() => onClick()}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      className="bi menu-controll menu-controll-close bi-list grow2"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M2.5 12a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
      ></path>
    </svg>
  );
};

export const MailIcon = ({ color = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
      <path
        fill="currentColor"
        d="M0 0v42h42V0zm10.31 11H32a2.28 2.28 0 011 .23l-9.24 9.23L21.09 23 9.32 11.23a2.28 2.28 0 01.99-.23zM8 28.71v-15.4a2.28 2.28 0 01.23-1L16.92 21l-8.69 8.7a2.28 2.28 0 01-.23-.99zM32 31H10.31a2.16 2.16 0 01-1-.24L18 22.1l2.53 2.53a.77.77 0 001.09 0l2.64-2.53L33 30.79a2.28 2.28 0 01-1 .21zm2.3-2.32a2.25 2.25 0 01-.23 1L25.36 21 34 12.32a2.24 2.24 0 01.23 1z"
      ></path>
    </svg>
  );
};

export const LinkedIcon = ({ color = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" data-name="Layer 1" viewBox="0 0 42 42">
      <path
        fill="currentColor"
        d="M0 0v42h42V0zm14.37 32h-5V16h5zm.39-21.08A2.9 2.9 0 1111.88 8a2.89 2.89 0 012.88 2.92zM33 32h-5v-7.8c0-1.86 0-4.2-2.56-4.2s-3 2-3 4.11V32h-5V16h4.77v2.18h.07a5.23 5.23 0 014.72-2.6c5 0 6 3.31 6 7.62z"
      ></path>
    </svg>
  );
};

export const DownIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      className="bi bi-chevron-down"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        fill="currentColor"
        d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
      ></path>
    </svg>
  );
};

export const DocsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-file-earmark-text"
      viewBox="0 0 16 16"
    >
      <path d="M5.5 7a.5.5 0 000 1h5a.5.5 0 000-1zM5 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5"></path>
      <path d="M9.5 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.5zm0 1v2A1.5 1.5 0 0011 4.5h2V14a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1z"></path>
    </svg>
  );
};

export const PlusIcon = ({ onClick }) => {
  return (
    <div style={{ transform: 'scale(1.6)', cursor: 'pointer' }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-plus-circle-dotted"
        viewBox="0 0 16 16"
      >
        <path d="M8 0q-.264 0-.523.017l.064.998a7 7 0 01.918 0l.064-.998A8 8 0 008 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 00-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 00-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 01.793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 00-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 00-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 00-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 00-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 000 1.046l.998-.064a7 7 0 010-.918zM16 8a8 8 0 00-.017-.523l-.998.064a7 7 0 010 .918l.998.064A8 8 0 0016 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 01-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 00.524.905l.83-.556a7 7 0 01-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 01-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 01-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 01-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 01-.884-.237zm4.132.271a8 8 0 001.012-.27l-.321-.948a7 7 0 01-.884.237l.194.98zm-2.083.135a8 8 0 001.046 0l-.064-.998a7 7 0 01-.918 0zM8.5 4.5a.5.5 0 00-1 0v3h-3a.5.5 0 000 1h3v3a.5.5 0 001 0v-3h3a.5.5 0 000-1h-3z"></path>
      </svg>
    </div>
  );
};

// export const FbIcon = () => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="32"
//       height="32"
//       fill="currentColor"
//       className="bi bi-facebook"
//       viewBox="0 0 16 16"
//     >
//       <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
//     </svg>
//   );
// };

export const AtIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      className="bi bi-at"
      viewBox="0 0 16 16"
    >
      <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"></path>
    </svg>
  );
};

export const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      className="bi bi-phone-vibrate"
      viewBox="0 0 16 16"
    >
      <path d="M10 3a1 1 0 011 1v8a1 1 0 01-1 1H6a1 1 0 01-1-1V4a1 1 0 011-1h4zM6 2a2 2 0 00-2 2v8a2 2 0 002 2h4a2 2 0 002-2V4a2 2 0 00-2-2H6z"></path>
      <path d="M8 12a1 1 0 100-2 1 1 0 000 2zM1.599 4.058a.5.5 0 01.208.676A6.967 6.967 0 001 8c0 1.18.292 2.292.807 3.266a.5.5 0 01-.884.468A7.968 7.968 0 010 8c0-1.347.334-2.619.923-3.734a.5.5 0 01.676-.208zm12.802 0a.5.5 0 01.676.208A7.967 7.967 0 0116 8a7.967 7.967 0 01-.923 3.734.5.5 0 01-.884-.468A6.967 6.967 0 0015 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 01.208-.676zM3.057 5.534a.5.5 0 01.284.648A4.986 4.986 0 003 8c0 .642.12 1.255.34 1.818a.5.5 0 11-.93.364A5.986 5.986 0 012 8c0-.769.145-1.505.41-2.182a.5.5 0 01.647-.284zm9.886 0a.5.5 0 01.648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 01-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 01.283-.648z"></path>
    </svg>
  );
};

export const MapIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      className="bi bi-geo-alt"
      viewBox="0 0 16 16"
    >
      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 018 14.58a31.481 31.481 0 01-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0110 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 002 6c0 4.314 6 10 6 10z"></path>
      <path d="M8 8a2 2 0 110-4 2 2 0 010 4zm0 1a3 3 0 100-6 3 3 0 000 6z"></path>
    </svg>
  );
};

export const FbIcon = ({ href }) => {
  return (
    <Link to={href} target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" data-name="Layer 1" viewBox="0 0 42 42">
        <path
          fill="currentColor"
          d="M0 0v42h42V0zm23.36 35.24v-10h3.32l.63-4.13h-3.94v-2.7a2.06 2.06 0 012.31-2.24h1.8v-3.52a21.77 21.77 0 00-3.19-.28c-3.25 0-5.37 2-5.37 5.57v3.16H15.3v4.14h3.61v10a14.24 14.24 0 114.45 0z"
        ></path>
      </svg>
    </Link>
  );
};

export const CommLabIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97.23"
      height="170.06"
      data-name="Layer 1"
      viewBox="0 0 97.23 170.06"
      className="logo-icon"
    >
      <g>
        <g fill="currentColor" data-name="Group 813">
          <path
            d="M699.88 345.33h-11.26v11.25h11.26z"
            data-name="Path 161"
            transform="translate(-634.59 -299.23)"
          ></path>
          <path d="M699.88 405h-11.26v11.26h11.26z" data-name="Path 162" transform="translate(-634.59 -299.23)"></path>
          <path
            d="M677.8 375.17h-11.26v11.26h11.26z"
            data-name="Path 163"
            transform="translate(-634.59 -299.23)"
          ></path>
        </g>
        <g data-name="Group 814">
          <path
            fill="currentColor"
            d="M634.59 424.65c0 30.61 20.71 44.65 48.62 44.65s48.62-14 48.62-44.65V299.23H721v125.42c0 24.31-16.2 33.84-37.81 33.84s-37.83-9.54-37.83-33.84V299.23h-10.8z"
            data-name="Path 164"
            transform="translate(-634.59 -299.23)"
          ></path>
        </g>
      </g>
    </svg>
  );
};
